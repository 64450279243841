import axios from 'axios'
// import {countCmsEntities} from './cms_service'
import {getParams} from './cms_service_helper.js'

export const useCmsConfigService = () => {
  let runtimeConfig
  try {
    runtimeConfig = useRuntimeConfig()
  } catch (_) {
    // useRuntimeConfig not available, must be used out of context
    runtimeConfig = {
      public: process.env
    }
  }
  // strapi-specificum with same paramaters in one query
  const baseurl = runtimeConfig.public.CMS_URL || 'https://cms-stg.dev.fst.com'
  const strapi_axios = axios.create({
    baseURL: baseurl
  })

  const getCmsEntityListAxios = async function (entityName, lang, skip, top, sort, sortDesc, filter, populateLocalizations = false) {
    if (typeof(skip) !== 'number' && typeof(top) !== 'number') {
      try {
        const totalCount = await countCmsEntities(entityName, lang, filter)
        let currentSkip = 0
        let promiseArray = []
        while (currentSkip < totalCount) {
          promiseArray.push(getCmsEntityListAxios(entityName, lang, currentSkip, 100, sort, sortDesc, filter, populateLocalizations))
          currentSkip += 100
        }
        let res = await Promise.all(promiseArray)
        return res.flat()
      } catch {
        return []
      }
    } else {
      try {
        const config = {}
        let params = getParams(lang, skip, top, sort, sortDesc, filter)
        if (entityName === 'translations' || entityName === 'textTranslations') {
          config.baseURL = runtimeConfig.public.TRANSLATION_CMS_URL || baseurl
          params = {...params, ...{'_where[_or][0][Entity_ne]': 'apiDocumentation', '_where[_or][1][Entity_null]': 'true'}}
        }

        const res = await strapi_axios.get(`/${entityName}`, {...config, params: params})
        return res.data
      } catch {
        return []
      }
    }
  }

  const getCmsEntityListWithParams = async function (entityName, params) {
    const config = {}
    if (entityName === 'translations' || entityName === 'textTranslations') {
      config.baseURL = runtimeConfig.public.TRANSLATION_CMS_URL || baseurl
    }

    const res = await strapi_axios.get(`/${entityName}`, {...config, params: params})
    return res.data
  }

  const getAllCategoryUrls = async (lang) => {
    let res = await getCmsEntityListAxios('category-contents', lang, 0, -1, null, null, null)
    return res.filter(c => c.Category).map(c => ({url: c.SEO && c.SEO.Url ? c.SEO.Url : c.Category.TechnicalName, technical_name: c.Category.TechnicalName, category_id: c.Category.id}))
  }

  const countCmsEntities = async function(entityName, lang, filter) {
    const config = {}
    if (entityName === 'translations' || entityName === 'textTranslations') {
      config.baseURL = runtimeConfig.public.TRANSLATION_CMS_URL || baseurl
    }

    const params = getParams(lang, null, null, null, null, filter)
    return (await strapi_axios.get(`/${entityName}/count`, {...config, params})).data
  }
  return {
    getCmsEntityListAxios,
    getCmsEntityListWithParams,
    getAllCategoryUrls
  }
}
