import Query from '../assets/js/query/index.js'
import {useCmsConfigService} from './cms_service_config.js'

export const useLocalizationService = () => {
  const {getCmsEntityListAxios, getCmsEntityListWithParams} = useCmsConfigService()

  const getLocales = async () => {
    try {
      return (await getCmsEntityListAxios('languages', null, 0, 100)).map(l => ({code: l.ShortName.toLowerCase(), iso: l.IsoCode, file: `${l.IsoCode}.ts`}))
    } catch {
      return [{code: 'de', iso: 'de-DE', file: 'de-DE.ts'}, {code: 'en', iso: 'en-US', file: 'en-US.ts'}]
    }
  }

  const getTranslationsForLocales = async (lang, rejectOnError = false) => {
    let locales = []
    if (!lang) {
      locales = await getLocales()
    }
    try {
      if (!lang) {
        const query = new Query()
        query.skip(0).top(-1).orderBy('Key,Entity,locale')
        return (await getCmsEntityListWithParams('translations', query.toStrapiParams({_locale: locales.map(l => l.code)})))
      }
      else {
        return (await getCmsEntityListAxios('translations', lang, 0, -1, 'Key,Entity,locale'))
      }
    }
    catch (err) {
      if (!rejectOnError) {
        return []
      }

      throw err
    }
  }

  const getTextTranslationsForLocales = async (lang, rejectOnError = false) => {
    let locales = []
    if (!lang) {
      locales = await getLocales()
    }
    try {
      if (!lang) {
        const query = new Query()
        query.skip(0).top(-1).orderBy('Key,locale')
        return (await getCmsEntityListWithParams('text-translations', query.toStrapiParams({_locale: locales.map(l => l.code)})))
      }
      else {
        return (await getCmsEntityListAxios('text-translations', lang, 0, -1, 'Key,locale'))
      }
    }
    catch (err) {
      if (!rejectOnError) {
        return []
      }

      throw err
    }
  }

  const getEntityTranslations = async (lang, entity, rejectOnError = false) => {
    let locales = []
    if (!lang) {
      locales = await getLocales()
    }
    try {
      if (!lang) {
        const query = new Query()
        query.skip(0).top(-1).orderBy('Key,Entity,locale')
        return (await getCmsEntityListWithParams('translations', query.toStrapiParams({_locale: locales.map(l => l.code), Entity: entity})))
      }
      else {
        return (await getCmsEntityListAxios('translations', lang, 0, -1, 'Key,Entity,locale', null, `Entity=${entity}`))
      }
    }
    catch (err) {
      if (!rejectOnError) {
        return []
      }

      throw err
    }
  }

  const getRegions = async () => {
    return (await getCmsEntityListAxios('regions')).map(r => (
      {
        RegionName: r.RegionName,
        RegionDescription: r.RegionDescription,
        RegionShortName: r.RegionShortName,
        RegionIcon: r.RegionIcon,
        RegionCountries: r.RegionCountries,
        IsDefaultRegion: r.IsDefaultRegion
      })
    )
  }

  const getBrands = () => {
    return getCmsEntityListAxios('brands', 'all')
  }

  const getPages = async () => {
    const pageTranslations = await getEntityTranslations(null, 'pages')
    // transform pageTranslations into a map of locale -> Key:Value
    const pages = {}
    pageTranslations.forEach(t => {
      if (!pages[t.locale]) {
        pages[t.locale] = {}
      }
      pages[t.locale][t.Key] = t.Value
    })

    return pages
  }

  return {
    getRegions,
    getPages,
    getBrands,
    getLocales,
    getTranslationsForLocales,
    getTextTranslationsForLocales,
    getEntityTranslations
  }
}
